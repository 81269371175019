const config = {
    host: (process.env.NODE_ENV === 'production') ? 'https://demo.dvgeo.app' : 'http://192.168.1.106:3016',
    homePage: (process.env.NODE_ENV === 'production') ? 'https://demo.dvgeo.app' : 'http://192.168.1.106:3016',
    api: 'api/',
    apiSocket: 'demo::1.2',
    nameDir: 'demo',
    package: 'app.dvgeo.demo.web',
    version: '1.0.2',
    googleAPIKey: 'AIzaSyDBXhaTlLCgVFvXL5Cnww3lC6oMM5SSB4Q',
    appName: 'DvGeo Demo',
    provider: 'Devjos CIA LTDA',
    colorPrimary: '#FF7601',
    colorDark: '#d25e01',
    colorAccent: '#ccc',
    colorTextPrimary: '#fff',
    colorTextDark: '#000',
    playStorePassengerId: 'app.dvgeo.demo',
    playStoreDriverId: 'app.dvgeo.demo.driver',
    appStorePassengerId: '',
    appStoreDriverId: '',
    email: "dvgeo.ec@gmail.com",
};
export default config;
